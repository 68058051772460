import React, { Component } from 'react';
import logo from '../../assets/logo-transparent.png';
import { BsFacebook, BsInstagram } from 'react-icons/bs';

import './Navbar.css';

class Navbar extends Component {

  state = { clicked: false }

  handleClick = () => {
    this.setState({clicked : !this.state.clicked})
  }

  render(){
    return(
      <nav id='navbar' className={this.state.clicked ? 'navbar active' : 'navbar'}>
        {/* LOGO */}
        <div className={this.state.clicked ? 'logo active' : 'logo'}>
          <img src={logo} alt='logo'></img>
        </div>

        <div className='menu-icon' onClick={this.handleClick}>
            <i className={this.state.clicked ? 'fas fa-times': 'fas fa-bars'}></i>
        </div>
        <ul className={this.state.clicked ? 'nav-menu active' : 'nav-menu'}>
          <ul className='links'>
            <li><a href='#about'   onClick={this.handleClick}>Over ons</a></li>
            <li><a href='#service' onClick={this.handleClick}>Diensten</a></li>
            <li><a href='#gallery' onClick={this.handleClick}>Galerij</a></li>
            <li><a href='#contact' onClick={this.handleClick}>Contact</a></li>
          </ul>
          <ul className='socialmedia'>
            <li><a href='https://www.facebook.com/Barber-Shop-Malinov-108036477717690/'><BsFacebook/></a></li>
            <li><a href='https://www.instagram.com/barbershopmalinov/'><BsInstagram/></a></li>
          </ul>
        </ul>
      </nav>
    )
  }
}

export default Navbar