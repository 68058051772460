import React, { Component } from 'react';
import { BsFillArrowUpCircleFill } from 'react-icons/bs';

import './Footer.css';

class Footer extends Component {
  render(){
    return(
      <footer className='footer'>
        <p>Copyright &copy; 2022 | Barbershop Malinov</p>
        <p>malinovorlin@hotmail.com</p>
        <p>BTW-nummer: BE0757640373</p>
        <a href='#navbar'><BsFillArrowUpCircleFill /></a>
      </footer>
    )
  }
}

export default Footer