export const haircuts = [
  {
    name: "Snit & Baard",
    amount: "30",
    time: "45",
    link: 'https://barbershopmalinov.setmore.com/services/2a8f2052-f1e5-48e8-892e-6aafc36e6f47?source=easyshare'
  },
  {
    name: "Mannen snit",
    amount:"20",
    time: "30",
    description: "snit & wassen",
    link: 'https://barbershopmalinov.setmore.com/services/367ff701-713c-45e7-8911-16d7eddf0b06?source=easyshare',
  },
  {
    name: "Kinderen tot 12 jaar",
    amount:"15",
    time: "30",
    link: 'https://barbershopmalinov.setmore.com/services/376b66b8-4db5-4e57-b874-84efff945bd6?source=easyshare',
  },
  {
    name: "MALINOV LUXE",
    amount: "40",
    time: "50",
    description: 'snit / baard / wassen / gezichtswax / gezichtsmasker',
    link: 'https://barbershopmalinov.setmore.com/services/b79f8a47-ed11-41f1-a4c6-4f86bdb398a4?source=easyshare',
  },
  {
    name: "Baard trimmen en Aflijnen",
    amount: "15",
    time: "30",
    link: 'https://barbershopmalinov.setmore.com/services/9c1b9ae4-0009-4220-b974-9868f2320a34?source=easyshare',
  },
  {
    name: "Scheren",
    amount: "15",
    time: "15",
    link: 'https://barbershopmalinov.setmore.com/services/4feb0e94-dbbc-4cc0-8e34-d9d71f89280c?source=easyshare',
  },
  {
    name: "Zwart masker",
    amount: "5",
    time: "30",
    link: 'https://barbershopmalinov.setmore.com/services/7f95205b-18fc-4196-b754-fc04bd0d85ed?source=easyshare',
  },
  {
    name: "Kill Mask",
    amount:"5",
    time: "10",
    link: 'https://barbershopmalinov.setmore.com/services/2aa24e62-8146-4108-8e5b-26e8da670611?source=easyshare',
  },
  {
    name: "Gezicht wax",
    amount: "3",
    time: "10",
    link: 'https://barbershopmalinov.setmore.com/services/fcad5550-b99a-49db-9bcf-f8e2f06cf472?source=easyshare',
  },
  {
    name: "Gezichtsverzorging",
    amount: "20",
    time: "20",
    description: 'facial steamer',
    link: 'https://barbershopmalinov.setmore.com/services/baa350ee-f541-4b98-9b17-a4502e061c2d?source=easyshare',
  },
]