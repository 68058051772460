import React, { Component } from 'react';
import { BsFillTelephoneFill } from 'react-icons/bs';
import { FiChevronsDown } from 'react-icons/fi';
import about_image from '../../../assets/haircut.jpg';
import contact_image from '../../../assets/chair.jpg';
import { barbers } from './barbers';
import Navbar from '../../navbar/Navbar';
import Footer from '../../footer/Footer';


import './Home.css'
import { haircuts } from './Haircuts';
import { ContactUs } from './ContactUs';

// images

import haar1 from './photos/haar1.jpg'
import haar2 from './photos/haar2.jpg'
import haar3 from './photos/haar3.png'
import haar4 from './photos/haar4.png'
import haar5 from './photos/haar5.jpg'
import haar6 from './photos/haar6.jpg'
import haar7 from './photos/haar7.jpg'
import haar9 from './photos/haar9.jpg'
import haar10 from './photos/haar10.jpg'
import haar12 from './photos/haar12.jpg'
import decoratie from './photos/decoratie.jpg'
import producten from './photos/producten.jpg'




// FUNCTION REVEAL 

function reveal() {
  var reveals = document.querySelectorAll(".reveal");

  for (var i = 0; i < reveals.length; i++) {
    var windowHeight = window.innerHeight;
    var elementTop = reveals[i].getBoundingClientRect().top;
    var elementVisible = 150;

    if (elementTop < windowHeight - elementVisible) {
      reveals[i].classList.add("active");
    } else {
      reveals[i].classList.remove("active");
    }
  }
}

window.addEventListener("scroll", reveal);

// 

class Home extends Component {

  render(){
    return(
        <div className='home'>
          <Navbar />

          <div className='banner'>
            <h1>Barbershop Malinov</h1>
            <FiChevronsDown size={50} className="chevron"/>
            <a href="https://barbershopmalinov.setmore.com/"><button>Boeken</button></a>
          </div>

          <div id='about'>
            <img src={about_image} className='reveal left' alt='about'></img>
            <div className='reveal'>
              <h1>Over ons</h1>
              <p>
                Wat is er nu beter dan ontspannen en rustig te genieten van een drankje, mannen onder elkaar en tegelijk je kapsel en baard letterlijk tot in de puntjes te laten verzorgen?
                Als je op zoek bent naar een complete ervaring dan moet je bij ons zijn!
                Barbershop Malinov staat voor luxe, verzorging en ontspanning!
              </p>
            </div>
          </div> 

          <div id='service'>
            <h1>Diensten</h1>
            <div className='barbers'>
              {barbers.map((item, index) => {
                return(
                  <div key={index}>
                    <img src={item.image} alt={item.name}></img>
                    <h3>{item.name}</h3>
                    <a href='https://barbershopmalinov.setmore.com/'><button>Nu boeken</button></a>
                  </div>
                )
              })}
            </div>
            <div className='haircuts'>
              {haircuts.map((item, index) => {
                return(
                  <div key={index}>
                    <div className={item.description !== undefined ? 'description' : ''}>
                    <h3>{item.name}</h3>
                    <small>{item.description}</small>
                    </div>
                    <div>
                    <h3>€ {item.amount}</h3>
                    </div>
                    <div>
                    <p>{item.time}min</p>
                    </div>
                    <div>
                    <a href={item.link}><button>Nu boeken</button></a>
                    </div>
                  </div>
                )
              })}
            </div>
          </div> 

          <div id='gallery'>
            <h1>Galerij</h1>
            <div className='photos'>
            <div className='img-wrapper'>
                <div>
                  <img src={decoratie} alt='haircut'></img>
                </div>   
              </div>  
              <div className='img-wrapper'>
                <div>
                  <img src={haar1} alt='haircut'></img>
                </div>   
              </div>  
              <div className='img-wrapper'>
                <div>
                  <img src={haar2} alt='haircut'></img>
                </div>   
              </div>  
              <div className='img-wrapper'>
                <div>
                  <img src={haar3} alt='haircut'></img>
                </div>   
              </div>  
              <div className='img-wrapper'>
                <div>
                  <img src={haar4} alt='haircut'></img>
                </div>   
              </div>  
              <div className='img-wrapper'>
                <div>
                  <img src={haar5} alt='haircut'></img>
                </div>   
              </div>  
              <div className='img-wrapper'>
                <div>
                  <img src={haar6} alt='haircut'></img>
                </div>   
              </div>  
              <div className='img-wrapper'>
                <div>
                  <img src={haar7} alt='haircut'></img>
                </div>   
              </div>  
              <div className='img-wrapper'>
                <div>
                  <img src={haar9} alt='haircut'></img>
                </div>   
              </div>  
              <div className='img-wrapper'>
                <div>
                  <img src={haar10} alt='haircut'></img>
                </div>   
              </div>  
              <div className='img-wrapper'>
                <div>
                  <img src={haar12} alt='haircut'></img>
                </div>   
              </div>  
              <div className='img-wrapper'>
                <div>
                  <img src={producten} alt='haircut'></img>
                </div>   
              </div>  
            </div>
          </div> 

          <div id='contact'>
            <img src={contact_image} alt='contact' className='reveal left contact'></img>
            <div id='form-container'>
              <h1>Contact</h1>
              <ContactUs />
            </div>
          </div>

          <div id='tpa' className='reveal tpa'>
            <div>
              <h2>Contact opnemen</h2>
              <p><BsFillTelephoneFill size={12}/>0466 38 88 35</p>
            </div>
            <div>
              <h2>Adres</h2>
              <p>Dendermondesteenweg 212, 9070 Destelbergen</p>
            </div>
            <div>
              <h2>Openingstijden</h2>
              <p>Di - Zo 10.00-20.00</p>
            </div>
          </div>
          
          <div id='map'>
            <iframe title='location' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d633.5563393760692!2d3.770631033410828!3d51.05400491973544!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c37799956d979f%3A0x5afeaf2e9d3b492a!2sBarbershop%20Malinov%20kapsalon!5e0!3m2!1snl!2sbe!4v1645042582487!5m2!1snl!2sbe" 
            loading="lazy"></iframe>
          </div>

          <Footer />
        </div>
    )
  }
}

export default Home
