import Home from './components/pages/home/Home';
import { useState, useEffect } from 'react';
import loadingImage from './assets/logo-transparent.png';
import ReactLoading from 'react-loading';

import './App.css';

function App() {

  const [done, setDone] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setDone(true);
    }, 1500);
  }, [])

  return (
    <div className="App">
      {
        !done ? 
        <div id='loading'>
        <img src={loadingImage} width={200} alt='loading'></img>
          <ReactLoading type={"bars"} color={"#CCA352"} height={50} width={50}/>
        </div>
        :
        <Home/>
      }
    </div>
  );
}

export default App;
